<template>
    <div>
        <component :is="dynamicComponent"></component>
    </div>
</template>

<script setup>
const props = defineProps({
    type: {
        type: String,
        required: true,
    }
});

const dynamicComponent = computed(() => {
    return defineAsyncComponent(() => import(`./icons/${props.type}.vue`));
});
</script>
